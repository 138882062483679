<template>
  <v-app class="ma-0 pa-0">
    <!-- <v-card outlined :style="this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'md'?'height: 100vh': ''">
          <v-card-text class="ma-0 pa-0"> -->
    <v-overlay absolute :value="loading">
      <v-progress-circular
        color=" #2E394F"
        indeterminate
        size="100"
      ></v-progress-circular>
    </v-overlay>
    <v-container fluid class="fill-height" ma-0 pa-0>
      <v-layout
        row
        wrap
        align-center
        justify-center
        class="fill-height"
        ma-0
        pa-0
      >
        <v-flex style="background-color:#FFCA05"
          lg9
          md7
          xs12
          :style="
            this.$vuetify.breakpoint.name === 'lg' ||
            this.$vuetify.breakpoint.name === 'md'
              ? 'height: 100%'
              : ''
          "
          v-if="
            this.$vuetify.breakpoint.name === 'lg' ||
              this.$vuetify.breakpoint.name === 'md'
          "
        >
          <v-img
            :src="require('@/assets/img/dhl-wallpaper.png')"
            max-height="100%"
          >
          </v-img>
        </v-flex>
        <v-flex lg3 md5 sm10 xs10>
          <v-layout row wrap align-center justify-center class="fill-height">
            <v-flex lg11 xs12 class="text-center pa-4 ma-1">
              <!-- <img
                class="ma-2"
                :src="require('@/assets/img/dhl-logo.png')"
                alt="John"
                height="100"
              /> -->
              <h2 class="red--text">DHL e-WitholdingTax</h2>
              <p style="font-size: 20px" class="mt-2">
                <b
                  style="color: black"
                  >เข้าสู่ระบบ</b
                >
              </p>
              <br />
              <v-form
                v-model="valid"
                ref="form"
              >
                <v-text-field
                  :rules="rulesUsername"
                  class="pl-2 pr-2"
                  dense
                  outlined
                  color="#2E394F"
                  label="Login"
                  v-model="username"
                  :disabled="logging"
                  prepend-icon="mdi-account"
                  @keypress.enter="call_api_login"
                  type="text"
                ></v-text-field>
                <v-text-field
                  class=" pl-2 pr-2"
                  :rules="rulesPassword"
                  dense
                  outlined
                  color="#2E394F"
                  id="password"
                  label="Password"
                  :disabled="logging"
                  @keypress.enter="call_api_login"
                  v-model="password"
                  :type="showpassword ? 'text' : 'password'"
                  :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                   @click:append="showpassword = !showpassword"
                  prepend-icon="mdi-lock"
                ></v-text-field>
              </v-form>
            </v-flex>
            <v-flex lg12 class="pr-4 pl-4  ml-4  mr-4">
              <v-btn
                color="#2E394F"
                dark
                depressed
                block
                :loading="logging"
                @click="fn_login()"
                ><b>เข้าสู่ระบบ</b></v-btn
              >
              <br />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
        <!-- ส่วนของปุ่มสถานะกำลังดาวน์โหลด -->
          <v-overlay :value="loadingStatus">
            <div class="text-center">กำลังเข้าสู่ระบบ ...</div>
            <v-progress-circular
              indeterminate
              size="64"
              class="ml-9 mt-3"
            ></v-progress-circular>
          </v-overlay>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal2 from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const Toast = Swal2.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
})

export default {
  data: () => ({
    loading: false,
    valid: true,
    username: '',
    password: '',
    taxid: '',
    logging: false,
    showpassword: false,
    loadingStatus: false,
    response_login: [],
    rulesUsername: [(v) => !!v || 'กรุณาระบุชื่อผู้ใช้'],
    rulesPassword: [(v) => !!v || 'กรุณาระบุรหัสผ่าน']
  }),
  computed: {
    ...mapGetters(['getter_dhl_profile']),
    _TaxId: {
      get () {
        return (this.getter_dhl_profile.id_card_num)
      }
    }
  },
  watch: {},
  methods: {
    fn_login: function () {
      if (this.$refs.form.validate()) {
        if (this.username === 'testonebilling') {
          this.taxid = '0105551019496'
        } else {
          this.taxid = '0105548000160'
        }
        this.loadingStatus = true
        const payload = {
          username: this.username,
          password: this.password,
          taxid: this.taxid
        }
        this.axios.post(process.env.VUE_APP_AUTHORIZE_API + '/login/v2', payload).then((res) => {
          console.log('login success', res)
          if (res.data.result === 'Success') {
            localStorage.setItem('DHL_Access', JSON.stringify(res.data))
            localStorage.setItem('DHL_Username', this.username)
            this.logging = false
            this.loadingStatus = false

            Toast.fire({
              icon: 'success',
              title: 'เข้าสู่ระบบ สำเร็จ'
            })
            this.$router.push('/total_report')
          } else {
            this.logging = false
            this.loadingStatus = false
            Toast.fire({
              icon: 'error',
              title: res.data.errorMessage
            })
            var datamsg = res.data.errorMessage
            if (datamsg === 'The user credentials were incorrect.') {
              Toast.fire({
                icon: 'error',
                title: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง !! กรุณาลองใหม่'
              })
            } else if (datamsg === 'Not Found Business Account') {
              Toast.fire({
                icon: 'error',
                title: 'ไม่พบบัญชีนิติบุคคล หรือบัญชีนิติบุคคลอยู่ในระหว่างตรวจสอบ'
              })
            } else {
              Toast.fire({
                icon: 'error',
                title: datamsg
              })
            }
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    }
  },
  mounted () {}
}
</script>
